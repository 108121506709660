// Timeline (core/elements/_timeline.scss)
$timeline-item--padding-v__mobile: 		48px;		// timeline item, top and bottom padding (mobile)
$timeline-item--padding-v__desktop: 	64px;		// timeline item, top and bottom padding (desktop)
$timeline-bullet--size: 			    10px;		// timeline bullet size
$timeline-bullet--radius: 			    2px;		// timeline bullet radius
$timeline-bullet--distance: 		    24px;		// distance from bullet to item (avoid null, prefer 0)
$timeline-line--width: 			        4px;		// timeline line width
$timeline-line--offset: 			    4px;		// distance from line to bullet (avoid null, prefer 0)

// Color
$timeline--color: (
	timeline-bullet: 					get-color(secondary, 1),
	timeline-line:       				get-color(light, 3),
	// ↓ Inverted colors
	timeline-bullet-inverse: 			get-color(light, 3),
	timeline-line-inverse: 	        	get-color(dark, 2)
);

// Timeline: font size
$timeline--size: (
	timeline-header:    'theta'
);

// Timeline: font weight
$timeline--weight: (
	timeline-header: 	500
);

// Don't change line below!
$icon--color:   map-push($icon--color, $timeline--color);
$font--size: 	map-push($font--size, $timeline--size);
$font--weight: 	map-push($font--weight, $timeline--weight);